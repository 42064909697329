<template>
  <div id="app">
    <UrlShortener />
  </div>
</template>

<script>
import UrlShortener from './components/UrlShortener.vue'

export default {
  name: 'App',
  components: {
    UrlShortener
  }
}
</script>