<template>
    <div class="url-shortener">
      <h1>TinyLeap URL Shortener</h1>
      <div>
        <input v-model="url" type="text" placeholder="Enter a URL to shorten">
        <button @click="shortenUrl">Shorten</button>
      </div>
      <div v-if="shortUrl" class="result">
        <p>Shortened URL:</p>
        <a :href="shortUrl" target="_blank">{{ shortUrl }}</a>
      </div>
      <p v-if="error" class="error">{{ error }}</p>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'UrlShortener',
    data() {
      return {
        url: '',
        shortUrl: '',
        error: ''
      }
    },
    methods: {
      async shortenUrl() {
        this.error = '';
        this.shortUrl = '';
        
        if (!this.url) {
          this.error = 'Please enter a URL';
          return;
        }
  
        try {
          const response = await axios.post('https://zt728xv9q1.execute-api.us-east-1.amazonaws.com/dev/url', { url: this.url });
          if (response.data && response.data.shortUrl) {
            this.shortUrl = response.data.shortUrl;
          } else {
            throw new Error('Unexpected API response');
          }
        } catch (err) {
          console.error('Error:', err);
          this.error = 'An error occurred while shortening the URL. Please try again.';
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .url-shortener {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  input {
    width: 70%;
    padding: 10px;
    font-size: 16px;
  }
  
  button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #42b983;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .error {
    color: red;
  }
  
  .result {
    margin-top: 20px;
  }
  </style>